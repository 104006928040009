import type { RouteState } from "@/__main__/router.mjs";
import definition from "@/game-deadlock/definition.mjs";
import getMatch from "@/game-deadlock/fetches/match.mjs";
import {
  getMatchlist,
  getMatchlistMeta,
} from "@/game-deadlock/fetches/matchlist.mjs";
import getProfileHeroStats from "@/game-deadlock/fetches/profile-hero-stats.mjs";
import getProfileStats from "@/game-deadlock/fetches/profile-stats.mjs";
import getSteam from "@/game-deadlock/fetches/steam.mjs";
import {
  setEmptyProfileInitial,
  setProfileByMatchlistMeta,
} from "@/game-deadlock/utils/actions.mjs";
import isObject from "@/util/is-object.mjs";
import { steam3to64 } from "@/util/steam.mjs";

export default async function fetchData(
  [steamId]: FixedLengthArray<string, 2>,
  _searchParam: URLSearchParams,
  state: RouteState,
): Promise<void> {
  await Promise.all([
    getSteam({
      steamId3: steamId,
      steamId64: steam3to64(steamId),
    }),
    ((): Promise<void> => {
      const matches = state.transient?.visibleMatches?.[definition.shortName];
      if (!isObject(matches)) return;
      for (const matchId in matches) {
        if (!matches[matchId]) continue;
        getMatch({ matchId });
      }
    })(),
    getProfileHeroStats({ steamId }),
    getProfileStats({ steamId }),
    (async () => {
      const matchlist = await getMatchlist({ steamId });
      const latestMatchId = matchlist?.[0];
      if (!latestMatchId) return setEmptyProfileInitial(steamId);
      if (
        !setProfileByMatchlistMeta(
          await getMatchlistMeta({ steamId, matchId: latestMatchId }),
        )
      ) {
        return;
      }
      setEmptyProfileInitial(steamId);
    })(),
  ]);
}
